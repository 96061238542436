import {useState} from "react";

const FormPreis = ({reff}) => {
    const [kaltmiete,setKaltmiete] = useState('');
    const [preisvorstellung,setPreisvorstellung] = useState('');

    return (
        <div className="formPreis" ref={reff}>
            <div className="row">
                <div className="feld">
                    <label htmlFor={'miete'}>Jahresnettokaltmiete</label>
                    <input type={'text'} id={'miete'} name={"kaltmiete"} value={kaltmiete} onChange={e => setKaltmiete(e.target.value)}/>
                </div>
                <div className="feld">
                    <label htmlFor={'preisvorstellung'}>Preisvorstellung</label>
                    <input type={'text'} id={'preisvorstellung'} name={"preisvorstellung"} value={preisvorstellung} onChange={e => setPreisvorstellung(e.target.value)}/>
                </div>
            </div>

        </div>
    );
}

export default FormPreis;