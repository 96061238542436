const About = () => {

    return (
        <div className="about" id={'about'}>
            <div className="row1">

                <div className="text1">
                    <h1>Über Uns</h1>
                    <p>Hinter der Seite mfhverkaufen.de steht die J.D. Investments GmbH. Dabei handelt es sich um ein
                        junges und dynamisches Unternehmen welches stetig auf der Suche nach neuen Objekten für den
                        eigenen Bestand ist. </p>

                </div>

                <div className="bild1" />

            </div>
            <div className="row2">
                <div className="bild2"/>

                <div className="text2">
                    <p>Wir haben es uns zum Ziel gesetzt für alle Beteiligten einen <span className={'bold'}>Mehrwert zu schaffen!</span>
                    </p>
                    <p>Dies erzielen wir durch die Instandhaltung und Optimierung der von uns erworbenen Objekte.
                    </p>
                    <p>Durch unsere kurzen Entscheidungswege, unsere starken und verlässlichen Partner sowie unser
                        unbürokratisches Vorgehen läuft der Ankauf bei uns unkompliziert, schnell und verbindlich. Für
                        Sie bedeutet das einen maximal <span className="bold">stressfreien Verkaufsprozess.</span></p>
                    <p>Wir freuen uns darauf Sie und Ihre Immobilien kennenzulernen!
                    </p>
                </div>
            </div>
        </div>
    )


}

export default About;