import {BiUserPin} from 'react-icons/bi'
import {MdPlaylistAddCheck} from 'react-icons/md'
import {HiOutlineUpload} from 'react-icons/hi'
import {FaHome} from 'react-icons/fa'
import {RiMoneyEuroCircleFill} from 'react-icons/ri'
import {useEffect, useRef, useState} from "react";
import React from 'react';
import FormDatenImmobilie from "./formDatenImmobilie";
import FormAusstattungImmobilie from "./formAusstattungImmobilie";
import FormUpload from "./formUpload";
import FormPreis from "./formPreis";
import FormPersoenlicheDaten from "./formPersoenlicheDaten";
import $ from 'jquery';
import FormDankeAnfrage from "./formDankeAnfrage";
const Angebot = () => {
    const formRefImo = useRef();
    const formRefAusstattung = useRef();
    const formRefUpload = useRef();
    const formRefPreis = useRef();
    const formRefPerson = useRef();
    const formDanke = useRef();
    const [activeIcon, setActiveIcon] = useState(1);
    const titel = ["DATEN DER IMMOBILIE", "AUSSTATTUNG DER IMMOBILIE", "FOTOS IHRER IMMOBILIE", "IHRE PREISVORSTELLUNG", "PERSÖNLICHE DATEN"];
    const formRef = useRef();
    useEffect(() => {
        if (activeIcon === 1) {
            formRefImo.current.style.display = 'flex'
            formRefImo.current.style.opacity = '1'

        } else {
            formRefImo.current.style.opacity = '0'
            formRefImo.current.style.display = 'none'
        }

        if (activeIcon === 2) {
            formRefAusstattung.current.style.display = 'flex'
        } else {
            formRefAusstattung.current.style.display = 'none'
        }
        if (activeIcon === 3) {
            formRefUpload.current.style.display = 'flex'
        } else {
            formRefUpload.current.style.display = 'none'
        }
        if (activeIcon === 4) {
            formRefPreis.current.style.display = 'flex'
        } else {
            formRefPreis.current.style.display = 'none'
        }
        if (activeIcon === 5) {
            formRefPerson.current.style.display = 'flex'
        } else {
            formRefPerson.current.style.display = 'none'
        }
        if (activeIcon === 6) {
            formDanke.current.style.display = 'flex'
        } else {
            formDanke.current.style.display = 'none'
        }
    })


    const handleLastFrom = (e) => {
        e.preventDefault();



        if (activeIcon > 1 ) {
            setActiveIcon((activeIcon - 1));
        }

        if(activeIcon >5 ){
            setActiveIcon(1)
        }
    }
    const handleNextForm = (e) => {
        if(activeIcon <5){
            e.preventDefault();
            setActiveIcon((activeIcon + 1));

        }
        if (activeIcon <=5 && formRefPerson.current.children[2].firstChild.checked) {
            setActiveIcon((activeIcon + 1));
        }
    }



    return (
        <div className="angebot" id={"angebot"} >
            <h1>Kostenloses Angebot anfordern</h1>
            <h3>Geben Sie die Parameter Ihrer Immobilie ein</h3>
            <h4 style={{textAlign: 'center',fontStyle: 'italic',fontWeight:100,marginTop: "1rem"}}>Es gibt keine Pflichtfelder! Sie brauchen nur die Informationen angeben, die Sie angeben möchten!</h4>
            <h4 style={{textAlign: 'center',fontStyle: 'italic'}}>Desto ausführlicher Ihre Angaben sind, umso präziser wird unser Angebot.</h4>

            <div className="iconbar">
                <FaHome size={50} color={activeIcon === 1 ? '#CC9933' : '#fff'} onClick={() => setActiveIcon(1)}/>
                <hr/>
                <MdPlaylistAddCheck size={50} color={activeIcon === 2 ? '#CC9933' : '#fff'}
                                    onClick={() => setActiveIcon(2)}/>
                <hr/>
                <HiOutlineUpload size={50} color={activeIcon === 3 ? '#CC9933' : '#fff'}
                                 onClick={() => setActiveIcon(3)}/>
                <hr/>
                <RiMoneyEuroCircleFill size={50} color={activeIcon === 4 ? '#CC9933' : '#fff'}
                                       onClick={() => setActiveIcon(4)}/>
                <hr/>
                <BiUserPin size={50} color={activeIcon === 5 ? '#CC9933' : '#fff'} onClick={() => setActiveIcon(5)}/>
            </div>

            <iframe width={0} height={0} name={'hiddenFrame'} id={'hiddenFrame'} style={{display: 'none'}} />
            <form id={"form"} method={"post"} ref={formRef} action={"https://www.mfhverkaufen.de/php/index.php"}  encType={"multipart/form-data"} target={'hiddenFrame'} >
                <h2>{titel[activeIcon - 1]}</h2>
                <FormDatenImmobilie reff={formRefImo}/>
                <FormAusstattungImmobilie reff={formRefAusstattung}/>
                <FormUpload reff={formRefUpload}/>
                <FormPreis reff={formRefPreis}/>
                <FormPersoenlicheDaten reff={formRefPerson}/>
                <FormDankeAnfrage reff={formDanke} />

                <div className="buttonbar">
                    <button onClick={handleLastFrom} style={activeIcon === 1 ? {display: 'none'}: {display:'block'}}>Zurück</button>
                    <button onClick={handleNextForm} style={activeIcon === 6 ? {display:"none"} : {display: 'block'}}>{activeIcon === 5 ? "Abschließen" : "Weiter"}</button>
                </div>

            </form>

        </div>
    )
}

export default Angebot


