import {AiOutlineMail, BsHouse, RiPhoneLine} from "react-icons/all";

const Kontakt = () => {
    return (
        <div className="kontakt" id={"kontakt"}>
            <div className="kontakt_feld">
                <h1>Kontakt</h1>
                <div className="row">
                    <div className="spalte1">
                        <div className="info">
                            <BsHouse size={50} color={'#CC9933'}/>
                            <div className="text">
                                <p className={"ueberschrift"}>Anschrift</p>
                                <p className={"information"}>Herderstr. 4, 42327, Wuppertal</p>
                            </div>
                        </div>
                        <div className="info">
                            <RiPhoneLine size={50} color={'#CC9933'}/>
                            <div className="text">
                                <p className={"ueberschrift"}>Telefon</p>
                                <p className={"information"}>+49 1725321198</p>
                            </div>
                        </div>
                        <div className="info">
                            <AiOutlineMail size={50} color={'#CC9933'}/>
                            <div className="text">
                                <p className={"ueberschrift"}>E-Mail</p>
                                <p className={"information"}>info@jdinvestments.de</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footertext">
                <details>
                    <summary>Impressum</summary>
                    <div className="inhalt">
                        <p>Angaben gemäß § 5 TMG</p>
                        <br/>
                        <p>Semen Dubinski</p>
                        <p>Herderstr. 4 42327</p>
                        <p>42327 Wuppertal</p>
                        <br/>
                        <p>Handelsregister: 30768</p>
                        <p>Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:
                            132 5930 1085</p>
                        <br/>
                        <p>Verbraucherstreitbeilegung / Universalschlichtungsstelle
                            Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                            Verbraucherschlichtungsstelle teilzunehmen.</p>
                    </div>
                </details>

            </div>
            <hr color={'#CC9933'}/>
            <p className={'copy'}>Copyright © 2020 by JD Investments. All rights reserved</p>

        </div>
    )
}

export default Kontakt