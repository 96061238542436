import './sass/main.scss';
import Topbar from "./component/topbar";
import Navigation from "./component/navigation";
import Header from "./component/header";
import Zielgruppe from "./component/zielgruppe";
import Angebot from "./component/angebot";
import {useEffect, useRef, useState} from "react";
import About from "./component/about";
import Empfehlung from "./component/empfehlung";
import Kontakt from "./component/kontakt";

function App() {
    //menuState
    const [menuState, setMenuState] = useState(false);

    const refHeader = useRef();
    const refNavigation = useRef();
    const sectionOneOptions = {
        rootMargin : "-50px"
    }

    const SectionOneObserver = new IntersectionObserver(
        (entries, sectionOneObserver) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    refNavigation.current.classList.add("scrollnav");
                } else {
                    refNavigation.current.classList.remove("scrollnav");

                }
            })
        }, sectionOneOptions)


    useEffect(() => {
        SectionOneObserver.observe(refHeader.current);

    })


    return (
        <div className="App">
            <Topbar />
            <Navigation menuState={menuState} setMenuState={setMenuState} reff={refNavigation} refHeader={refHeader}/>
            <Header reff={refHeader}/>
            <Zielgruppe/>
            <Angebot/>
            <About />
            <Empfehlung />
            <Kontakt />
        </div>
    );
}

export default App;
