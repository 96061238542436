import {useState} from "react";

const FormPersoenlicheDaten = ({reff}) => {

    const [vorname,setVorname] = useState('');
    const [nachname,setNachname] = useState('');
    const [email,setEmail] = useState('');
    const [telefon,setTelefon] = useState('');

    return (
        <div className="formPersoen" ref={reff}>
            <div className="row">
                <div className="feld">
                    <label htmlFor={'vormane'}>Vorname</label>
                    <input type={'text'} id={'vorname'} name={'vorname'} value={vorname} onChange={e => setVorname(e.target.value)}/>
                </div>
                <div className="feld">
                    <label htmlFor={'nachname'}>Nachname</label>
                    <input type={'text'} id={'nachname'} name={'nachname'} value={nachname} onChange={e => setNachname(e.target.value)}/>
                </div>
            </div>
            <div className="row">
                <div className="feld">
                    <label htmlFor={'email'}>E-Mail</label>
                    <input type={'text'} id={'email'} name={'email'} value={email} onChange={e => setEmail(e.target.value)}/>
                </div>
                <div className="feld">
                    <label htmlFor={'telefon'}>Telefon</label>
                    <input type={'text'} id={'telefon'} name={'telefon'} value={telefon} onChange={e => setTelefon(e.target.value)}/>
                </div>
            </div>
            <div className="datenschutz">
                <input type="checkbox" id={"datenschutz"} name={"Datenschutz aktzeptieren"} required/>
                <label htmlFor={"datenschutz"}>Datenschutz aktzeptieren</label>
            </div>
        </div>
    );
}

export default FormPersoenlicheDaten;