
const FormDankeAnfrage = ({reff}) => {


    return (
        <div className="formPersoen" ref={reff}>
            <div >
                <h1 style={{color: '#8b7742'}}>Danke für Ihre Anfrage.</h1>
                <p style={{color: '#8b7742'}}>Wir werden uns so schnell wie möglich bei Ihnen zurück melden.</p>
            </div>

        </div>
    );
}

export default FormDankeAnfrage;