const Header = ({reff}) => {

    return (
        <div className="header" id={"start"} ref={reff} >
            <div className="top">
                <h1 id={'gold'}>SIE MÖCHTEN IHRE IMMOBILIE VERKAUFEN?</h1>
                <h2 id={'gold'}>ERHALTEN SIE INNERHALB VON 48 STD UNSER ANGEBOT!</h2>
            </div>
            <h3 id={'gold'}>Seriös, unkompliziert, diskret!</h3>
        </div>
    )
}

export default Header