import ReactTooltip from "react-tooltip";
import einfamilienhaus from "../images/angebot/einfamilienhaus.png";
import mehrfamilienhaus from "../images/angebot/mehrfamilienhaus.png";
import etagenwohnung from "../images/angebot/etagenwohnung.png";
import {useState} from "react";

const FormDatenImmobilie = ({reff}) => {

    const[straße,setStraße] = useState('');
    const[art,setArt] = useState('');
    const[gewerbeanteil,setGewerbeanteil] = useState('');
    const[baujahr,setBaujahr] = useState('');
    const[grundstuecksflaeche,setGrundstuecksflaeche] = useState('');
    const[ort,setOrt] = useState('');
    const[einheiten,setEinheiten] = useState('');
    const[gewerbeflaeche,setGewerbeflaeche] = useState('');
    const[wohnflaeche,setWohnflaeche] = useState('');



    return (
        <div className="formDatenImmobilie" ref={reff}>
            <div className="felder">
                <div className="spalte1">
                    <div className="feld">
                        <label htmlFor={'straße'}>Straße und Hausnummer</label>
                        <input type={'text'} id={'straße'} name={"straße"} value={straße} onChange={e => setStraße(e.target.value)}/>
                    </div>
                    <div className="feld">
                        <label>Art der Immobilie</label>
                        <div className="radioBar" onChange={e => setArt(e.target.value)}>
                            <input type={"radio"} id={"einfamilienhaus"} name={"art"} value={"einfamilienhaus"} />
                            <label data-tip="Einfamilienhaus" htmlFor={'einfamilienhaus'}>
                                <ReactTooltip className={"tooltip"}/>
                                <img src={einfamilienhaus} alt="einfamilienhaus"/>
                            </label>

                            <input type={"radio"} id={"mehrfamilienhaus"} name={"art"} value={"mehrfamilienhaus"}/>
                            <label data-tip="Mehrfamilienhaus" htmlFor={'mehrfamilienhaus'}>
                                <ReactTooltip className={"tooltip"}/>
                                <img src={mehrfamilienhaus} alt="mehrfamilienhaus"/>
                            </label>

                            <input type={"radio"} id={"etagenwohnung"} name={"art"} value={"etagenwohnung"}/>
                            <label data-tip="Etagenwohnung" htmlFor={'etagenwohnung'}>
                                <ReactTooltip className={"tooltip"}/>
                                <img src={etagenwohnung} alt="etagenwohnung"/>
                            </label>
                        </div>
                    </div>
                    <div className="feld">
                        <label>Gewerbeanteil?</label>
                        <div className="radioBar">
                            <input type={"radio"} id={"Nein"} value={"Nein"} name={"gewerbeanteil"} />
                            <label htmlFor={'Nein'}>Nein</label>

                            <input type={"radio"} id={"Ja"} value={"Ja"} name={"gewerbeanteil"}/>
                            <label htmlFor={'Ja'}>Ja</label>
                        </div>
                    </div>
                    <div className="feld">
                        <label htmlFor={'baujahr'}>Baujahr</label>
                        <input type={'text'} id={'baujahr'} name={"Baujahr"} value={baujahr} onChange={e =>setBaujahr(e.target.value)}/>
                    </div>
                    <div className="feld">
                        <label htmlFor={'grundstücksfläche'}>Grundstücksfläche</label>
                        <input type={'text'} id={'grundstücksfläche'} name={"Grundstücksfläche"} value={grundstuecksflaeche} onChange={e => setGrundstuecksflaeche(e.target.value)}/>
                    </div>
                </div>
                <div className="spalte2">
                    <div className="feld">
                        <label htmlFor={'ort'}>PLZ & Ort</label>
                        <input type={'text'} id={'ort'} name={"ort"} value={ort} onChange={e =>setOrt(e.target.value)}/>
                    </div>
                    <div className="feld">
                        <label htmlFor={'einheiten'}>Anzahl der Einheiten</label>
                        <input type={'text'} id={'einheiten'} name={"AnzahlEinheiten"} value={einheiten} onChange={e =>setEinheiten(e.target.value)}/>
                    </div>
                    <div className="feld">
                        <label htmlFor={'gewerbeanteilFlaeche'}>Gewerbeanteil Fläche</label>
                        <input type={'text'} id={'gewerbeanteilFlaeche'} name={"GewerbeanteilFläche"} value={gewerbeflaeche} onChange={e =>setGewerbeflaeche(e.target.value)}/>
                    </div>
                    <div className="feld">
                        <label htmlFor={'whohnfläche'}>Wohnfläche</label>
                        <input type={'text'} id={'whohnfläche'} name={"whohnfläche"} value={wohnflaeche} onChange={e =>setWohnflaeche(e.target.value)} />
                    </div>
                    <div className="feld">
                        <label htmlFor={'besitzverhaeltnis'} >Besitzverhältnis</label>
                            <select id={'besitzverhaeltnis'} name={"Besitzverhältnis"} >
                                <option value={"Kaufgrundstueck"}>Kaufgrundstück</option>
                                <option value={"Erbpacht"}>Erbpacht</option>
                            </select>

                    </div>
                </div>

            </div>
        </div>

    );

}
export default FormDatenImmobilie;