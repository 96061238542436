import FormDankeAnfrage from "./formDankeAnfrage";
import {useEffect, useRef, useState} from "react";

const Empfehlung = () => {

    const formDanke = useRef();
    const formEmpfehlung = useRef();
    const [send,setSend] = useState(false);

    useEffect(() => {
        if (send) {


        } else {
            formEmpfehlung.current.style.opacity = '0'
            formEmpfehlung.current.style.display = 'none'
        }

        if (send ) {
            formDanke.current.style.display = 'flex'
        } else {
        }

        if (send){
            formEmpfehlung.current.style.opacity = '0'
            formEmpfehlung.current.style.display = 'none'

            formDanke.current.style.display = 'flex'
            formDanke.current.style.opacity = '1'

        }else{
            formDanke.current.style.display = 'none'
            formDanke.current.style.opacity = '0'

            formEmpfehlung.current.style.display = 'flex'
            formEmpfehlung.current.style.opacity = '1'

        }


    })


    return (
        <div className="empfehlung" id={'empfehlung'}>
            <h1>Tippgeber-Provision</h1>
            <h2>Kennen Sie jemanden der seine Immobilie verkaufen möchte?</h2>

            <h4>Wenn ein Kauf durch Ihren Tipp zustande kommt, zahlen wir je nach Transaktionsvolumen eine Provision von bis zu <span className="bold">500€</span> an
                Sie aus.
            </h4>
            <form method={"post"} action="https://www.mfhverkaufen.de/php/tipp.php" target={'hiddenFrame'} onSubmit={() => setSend(true)}>
                <div ref={formEmpfehlung} className="felder">
                    <div className="spalte1">
                        <div className="feld">
                            <label htmlFor={'vorname'}>Vorname</label>
                            <input type={'text'} id={'vorname'} name={'vorname'}/>
                        </div>
                        <div className="feld">
                            <label htmlFor={'nachname'}>Nachname</label>
                            <input type={'text'} id={'nachname'} name={"nachname"}/>
                        </div>
                        <div className="feld">
                            <label htmlFor={'email'}>E-Mail</label>
                            <input type={'text'} id={'email'} name={"email"}/>
                        </div>
                    </div>
                    <div className="spalte2">
                        <div className="feld">
                            <label htmlFor={'telefon'}>Telefon</label>
                            <input type={'text'} id={'telefon'} name={'telefon'}/>
                        </div>
                        <div className="feld">
                            <label htmlFor={'nachricht'}>Nachricht</label>
                            <textarea id={'nachricht'} name={"nachricht"}/>
                        </div>

                    </div>
                    <button  type={'submit'}>Senden</button>

                </div>
                <FormDankeAnfrage reff={formDanke}/>


            </form>
        </div>
    )
}
export default Empfehlung


//            <h3>Wenn ein Kauf durch Ihren Tipp zustande kommt, gibt es je nach Transaktionsvolumen, bis zu <span className="bold">500€</span> für
//                 Sie.
//             </h3>