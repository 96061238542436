import {useState} from "react";

const FormAusstattungImmobilie = ({reff}) => {
    const [heizung, setHeizung] = useState('')
    const [area1, setArea1] = useState('')
    const [area2, setArea2] = useState('')
    const [area3, setArea3] = useState('')
    return (
        <div className="formAusstattungImmobilie" ref={reff}>
            <div className="feldreihe">
                <div className="feld">
                    <label>Wie wird geheizt?</label>
                    <div className="radioBar" onChange={e => setHeizung(e.target.value)}>
                        <input type={"radio"} id={"gas1"} name={"heizung"} value={"Gas (zentral)"}/>
                        <label htmlFor={'gas1'}>Gas (zentral)</label>
                        <input type={"radio"} id={"gas2"} name={"heizung"} value={'Gas (etage)'}/>
                        <label htmlFor={'gas2'}>Gas (Etage)</label>
                        <input type={"radio"} id={"ol"} name={"heizung"} value={'Öl (zentral)'}/>
                        <label htmlFor={'ol'}>Ölheizung</label>
                        <input type={"radio"} id={"fern"} name={"heizung"} value={'Fernwärme'}/>
                        <label htmlFor={'fern'}>Fernwärme</label>
                        <input type={"radio"} id={"sonst"} name={"heizung"} value={'Sonstige'}/>
                        <label htmlFor={'sonst'}>Sonstige</label>

                    </div>
                    <div className="feld2">
                        <label htmlFor={'heizungBaujahr'}>Baujahr Heizung</label>
                        <input type={'text'} id={'heizungBaujahr'} name={'heizungBaujahr'}/>
                    </div>
                </div>

            </div>

            <div className="feldreihe">


                <div className="feld">
                    <label>Ist die Fassade gedämmt?</label>
                    <div className="radioBar">
                        <input type={"radio"} id={"Nein_gedämt"} name={"gedämt"} value={'Nein'}/>
                        <label htmlFor={'Nein_gedämt'}>Nein</label>

                        <input type={"radio"} id={"Ja_gedämt"} name={"gedämt"} value={'Ja'}/>
                        <label htmlFor={'Ja_gedämt'}>Ja</label>
                    </div>
                </div>
                <div className="feld">
                    <label>Sind Balkone vorhanden?</label>
                    <div className="radioBar">
                        <input type={"radio"} id={"Nein_balkon"} name={"balkon"} value={'Nein'}/>
                        <label htmlFor={'Nein_balkon'}>Nein</label>

                        <input type={"radio"} id={"Ja_balkon"} name={"balkon"} value={'Ja'}/>
                        <label htmlFor={'Ja_balkon'}>Ja</label>
                    </div>
                </div>
                <div className="feld">
                    <label>Sind die Fenster doppelverglast?</label>
                    <div className="radioBar">
                        <input type={"radio"} id={"Nein_glas"} name={"glas"} value={'Nein'}/>
                        <label htmlFor={'Nein_glas'}>Nein</label>

                        <input type={"radio"} id={"Ja_glas"} name={"glas"} value={'Ja'}/>
                        <label htmlFor={'Ja_glas'}>Ja</label>
                    </div>
                </div>
            </div>
            <div className="feldreihe">
                <div className="feld">
                    <label>Wie ist der Zustand der Immobilie?</label>
                    <div className="radioBar">
                        <input type={"radio"} id={"gepflegt"} name={"zustand"} value={'Gepflegt'}/>
                        <label htmlFor={'gepflegt'}>Gepflegt</label>

                        <input type={"radio"} id={"renovierung"} name={"zustand"} value={'Renovierungsbedürftig'}/>
                        <label htmlFor={'renovierung'}>Renovierungsbedürftig</label>
                    </div>
                </div>

                <div className="feld">
                    <label>Steht das Gebäude unter Denkmalschutz?</label>
                    <div className="radioBar">
                        <input type={"radio"} id={"Nein_denkmal"} name={"denkmal"} value={'Nein'}/>
                        <label htmlFor={'Nein_denkmal'}>Nein</label>

                        <input type={"radio"} id={"Ja_denkmal"} name={"denkmal"} value={'Ja'}/>
                        <label htmlFor={'Ja_denkmal'}>Ja</label>
                    </div>
                </div>
            </div>
            <div className="feldreihe">
                <div className="feld" style={{flexDirection: 'column', display: "flex",}}>
                    <label>Welche Sanierungsmaßnahmen wurden getätigt?</label>
                    <textarea id={"sanierung"} name={"welcheSanierung"} value={area1}
                              onChange={e => setArea1(e.target.value)}/>
                </div>
                <div className="feld" style={{flexDirection: 'column', display: "flex",}}>
                    <label>Wann wurden die genannten Maßnahmen getätigt?</label>
                    <textarea id={"wann"} name={"wannSanierung"} value={area2}
                              onChange={e => setArea2(e.target.value)}/>
                </div>
                <div className="feld" style={{flexDirection: 'column', display: "flex"}}>
                    <label>Möchten Sie uns noch etwas zu Ihrer Immobilie mitteilen?</label>
                    <textarea id={"weitereInformationen"} name={"weitereInformationen"} value={area3}
                              onChange={e => setArea3(e.target.value)}/>
                </div>
            </div>
        </div>
    );
}

export default FormAusstattungImmobilie;