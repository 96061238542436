import {AiOutlineArrowRight, GiHamburgerMenu} from "react-icons/all";
import {useEffect, useRef} from "react";
import Scrollspy from 'react-scrollspy'

const Navigation = ({menuState, setMenuState, reff, refHeader}) => {
    const refSideMenu = useRef();
    const refSidemenuContainer = useRef();
    const handleMenuState = () => {
        if (menuState) {
            setMenuState(false);

        } else {
            setMenuState(true);
        }
    }
    const sectionOneOptions = {
        rootMargin: "-500px"
    }
    const SectionOneObserver = new IntersectionObserver(
        (entries, sectionOneObserver) => {
            entries.forEach(entry => {
                if (!entry.isIntersecting) {
                    refSidemenuContainer.current.classList.add("scrollul");
                } else {
                    refSidemenuContainer.current.classList.remove("scrollul");

                }
            })
        }, sectionOneOptions)

    useEffect(() => {
        if (menuState === true) {
            setTimeout(() => refSideMenu.current.style.right = '0', 1
            )
            refSideMenu.current.style.display = 'flex'
        } else {
            setTimeout(() => refSideMenu.current.style.display = 'none', 500
            )
            refSideMenu.current.style.right = '-150%'
        }
        SectionOneObserver.observe(refHeader.current);
    })


    return (
        <>
            <div className={"navigation"}>
                <div className="" ref={reff}>
                    <Scrollspy className={"menu"} items={['start', 'angebot', 'empfehlung', 'kontakt', 'about']}
                               currentClassName="active">
                        <li><a href={'#start'}>START</a></li>
                        <li><a href={'#angebot'}>ANGEBOT ANFORDERN</a></li>
                        <li><a href={'#empfehlung'}>OHNE IMMOBILIE 500€ VERDIENEN</a></li>
                        <li><a href={'#kontakt'}>KONTAKT</a></li>
                        <li><a href={'#about'}>Über Uns</a></li>
                    </Scrollspy>
                </div>
                <div className="sidemenuContainer" ref={refSidemenuContainer}>
                    <GiHamburgerMenu onClick={handleMenuState} color={"#CC9933"} size={40}/>
                    <ul className={"sideMenu"} ref={refSideMenu}>
                        <li><a href={'#start'}>START</a></li>
                        <li><a href={'#angebot'}>ANGEBOT ANFORDERN</a></li>
                        <li><a href={'#empfehlung'}>OHNE IMMOBILIE 500€ VERDIENEN</a></li>
                        <li><a href={'#kontakt'}>KONTAKT</a></li>
                        <li><a href={'#about'}>Über Uns</a></li>
                        <div onClick={() => setMenuState(false)} className="placeButton" style={{alignItems: "center"}}>
                            <p>ZURÜCK</p> <AiOutlineArrowRight/>
                        </div>
                    </ul>
                </div>
            </div>
        </>

    )
}

export default Navigation;