import einfamilienhaus from '../images/zielgruppe/einfamilienhaus.png'
import mehrfamilienhaus from '../images/zielgruppe/mehrfamilienhaus.png'
import etagenwohnung from '../images/zielgruppe/etagenwohnung.png'


const Zielgruppe = () => {

    return (
        <div  className={'zielgruppe'}>
            <h1>Wir Suchen!</h1>
            <div className="objekt-reihe">
                <div className="objekt">
                    <div className="container">
                        <img src={einfamilienhaus} alt="einfamilienhaus"/>
                    </div>
                    <p>Einfamilienhaus</p>
                </div>
                <div className="objekt">
                    <div className="container">
                        <img src={mehrfamilienhaus} alt="mehrfamilienhaus"/>
                    </div>
                    <p>Mehrfamilienhaus</p>
                </div>
                <div className="objekt">
                    <div className="container">
                        <img src={etagenwohnung} alt="etagenwohnung"/>
                    </div>
                    <p>Etagenwohnung</p>
                </div>
            </div>
        </div>
    )
}
export default Zielgruppe