
const Topbar = () => {


    return(
        <div className={'topbar'} id={"topbar"} >
            <div className="topbar_image">
                <h1><span id={'gold'}>MFH</span>VERKAUFEN<span id='gold'>.</span>DE</h1>
            </div>
        </div>
    )

}

export default Topbar;