import {AiOutlineCloudUpload} from 'react-icons/ai'

const FormUpload = ({reff}) => {


    return (
        <div className="formUpload" ref={reff}>
            <div className="felder">
                <div className="feld">
                    <label htmlFor="grundriss">Grundriss <AiOutlineCloudUpload size={50}/>
                    </label>
                    <input type="file" id="grundriss" name="grundriss"/>
                </div>
                <div className="feld">
                    <label htmlFor="innen">Bilder von Innen <AiOutlineCloudUpload size={50}/>
                    </label>
                    <input type="file" id="innen" name="innen"/>
                </div>
                <div className="feld">
                    <label htmlFor="aussen">
                        <p>Bilder von Außen</p>
                        <AiOutlineCloudUpload size={50}/>
                    </label>
                    <input type="file" id="aussen" name="aussen"/>
                </div>
            </div>

        </div>
    )
}

export default FormUpload;